import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import "./StatusSection.css";

const statusMessages = {
  operational: "All Services Available",
  degraded: "Minor Service Issues",
  down: "Major Service Outages",
};

const calculateOperationalPercentage = (daysStatus) => {
  // Filter out days without a status
  const filteredStatus = daysStatus.filter(
    (status) =>
      status === "operational" || status === "degraded" || status === "down"
  );

  const totalDays = filteredStatus.length;
  const operationalDays = filteredStatus.filter(
    (status) => status === "operational"
  ).length;

  return totalDays > 0
    ? ((operationalDays / totalDays) * 100).toFixed(2)
    : "0.00";
};

const generateDaysStatus = (daysData) => {
  const statuses = new Array(60).fill("unknown"); // Default to "unknown"
  const today = new Date();
  daysData.forEach((day) => {
    const date = new Date(day.date);
    const diffDays = Math.floor((today - date) / (1000 * 60 * 60 * 24));
    if (diffDays < 60) {
      statuses[59 - diffDays] = day.status;
    }
  });
  return statuses;
};

function StatusSection() {
  const [services, setServices] = useState([]);
  const [overallStatus, setOverallStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/status")
      .then((response) => {
        console.log("Fetched data:", response.data); // Add this line
        setServices(response.data.services);
        setOverallStatus(response.data.overall_status);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="status-section">
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="system-status">
            <span className={`status-label ${overallStatus}`}>
              {statusMessages[overallStatus]}
            </span>
          </div>
          <div className="service-status-list">
            {services.map((service) => (
              <div key={service.name} className="service-status-item">
                <div
                  className={`status-circle ${service.status.toLowerCase()}`}
                ></div>
                <span>{service.name}</span>
              </div>
            ))}
          </div>
          <p className="uptime-message">Uptime History (past 60 days):</p>
          <Accordion type="single" collapsible>
            {services.map((service) => {
              const daysStatus = generateDaysStatus(
                service.daily_statuses || []
              );
              const operationalPercentage =
                calculateOperationalPercentage(daysStatus);

              return (
                <AccordionItem key={service.name} value={service.name}>
                  <AccordionTrigger>
                    <div className="status-item">
                      <span>{service.name}</span>
                      <div className="status-days">
                        {daysStatus.map((status, index) => (
                          <div
                            key={index}
                            className={`status-day ${status}`}
                          ></div>
                        ))}
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div
                      className={`status-card ${service.status.toLowerCase()}`}
                    >
                      {operationalPercentage}% uptime
                    </div>
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
        </>
      )}
    </div>
  );
}

export default StatusSection;
