import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PastIncidentsSection.css";

function PastIncidentsSection() {
  const [incidents, setIncidents] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/incidents") // Adjust the endpoint if necessary
      .then((response) => {
        console.log("Fetched incidents:", response.data); // Add this line

        // Sort incidents by date in descending order (most recent first)
        const sortedIncidents = response.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );

        setIncidents(sortedIncidents);
      })
      .catch((error) => {
        console.error("There was an error fetching the incidents!", error);
      });
  }, []);

  return (
    <div className="past-incidents-section">
      <h2>Past Incidents</h2>
      <div className="incidents-list">
        {incidents.length > 0 ? (
          incidents.map((incident) => (
            <div key={incident.id} className="incident-item">
              <div className="incident-date">
                {new Date(incident.date).toLocaleDateString()}
              </div>
              <div className="incident-title">{incident.title}</div>
              <div className="incident-details">{incident.details}</div>
            </div>
          ))
        ) : (
          <p>No incidents to display.</p>
        )}
      </div>
    </div>
  );
}

export default PastIncidentsSection;
