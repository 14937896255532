import React from "react";
import "./Navbar.css";

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <img src="/FCLogo.png" alt="Logo" className="logo" />
      </div>
      <h1 style={{ fontSize: "28px" }}>Status Page</h1>
    </nav>
  );
}

export default Navbar;
