import React from "react";
import "./App.css";
import Navbar from "./sections/Navbar";
import StatusSection from "./sections/StatusSection";
import PastIncidentsSection from "./sections/PastIncidentsSection";
import Footer from "./sections/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="main-content">
        <StatusSection />
        <PastIncidentsSection />
      </div>
      <Footer />
    </div>
  );
}

export default App;
